import fi from './locales/fi'
import en from './locales/en'

export default defineI18nConfig(() => ({
  legacy: false,
  locale: 'fi',
  fallbackLocale: 'en',
  messages: {
    en,
    fi
  }
}))
