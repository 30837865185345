export default {
  frontPage: 'Frontpage',
  cart: 'Shopping Cart',
  requestQuote: 'Request a Quote',
  sendQuoteRequest: 'Send Quote Request',
  businessCustomer: 'Business Customer',
  message: 'Message',
  quoteCart: 'Quote Cart',
  price: 'Price',
  ownAccount: 'Account',
  login: 'Login',
  name: 'Name',
  firstname: 'Firstname',
  lastname: 'Lastname',
  email: 'Email',
  phoneNumber: 'Phone number',
  company: 'Company',
  streetAddress: 'Street address',
  postcode: 'Postcode',
  city: 'City',
  country: 'Country',
  finland: 'Finland',
  password: 'Password',
  buttonCreateAccount: 'Create account',
  accountCreatedSuccessfully: 'Account created',
  accountCreatingError: 'Error, please try again',
  register: 'Create account',
  loggedOut: 'Logged out successfully',
  accountNotVerified: 'Account is not verified',
  passwordInfo: 'Enter at least 8 characters, using lowercase and uppercase letters, numbers, and special characters.',
  hiFirstname: 'Hi {firstname}',
  ownDetails: 'User details',
  checkUserDetails: 'Check user details',
  orders: 'Orders',
  orderInfo: 'Here you can find you\'re orders.',
  seeOrders: 'Check orders',
  marketingPermissions: 'Marketing permissions',
  editMarketingPermissions: 'Edit marketing permissions',
  wishlists: 'Wishlists',
  browseYourWishlists: 'Browse your wishlists',
  noWishlists: 'You dont have any wishlists.',
  errorOnLogin: 'Error on login, try again.',
  emailNotVerified: 'Email not verified.',
  checkEmailAndPassword: 'Wrong email or password',
  close: 'Close',
  logOut: 'Log Out',
  logIn: 'Log In',
  images: 'Images',
  addToCart: 'Add to cart',
  amountInCart: '{n} in cart',
  amountInQuoteCart: '{n} in quote cart',
  backToProduct: 'Back to product',
  largeImages: 'Large images',
  threeDImage: '3D image',
  video: 'Video',
  imagesFromCustomers: 'Customer images',
  amountOfReviews: 'No reviews | 1 review | {n} reviews',
  writeAReview: 'Write a review',
  addToWishlist: 'Add to wishlist',
  removeFromWishlist: 'Remove from wishlist',
  customerService: 'Customer service',
  cartEmpty: 'Cart is empty',
  pcs: 'pcs | {n} pc | {n} pcs',
  quantity: 'Quantity',
  removeDiscountCode: 'Remove discount code',
  proceedToCheckout: 'Proceed to Checkout',
  summary: 'Summary',
  clearCart: 'Clear cart',
  clearQuoteCart: 'Clear quote cart',
  shareCart: 'Share cart',
  error: 'Error',
  specialOffers: 'Special Offers',
  closeNotification: 'Close notification',
  satisfiedCustomers: 'Satisfied customers',
  fastDeliveries: 'Fast deliveries',
  paymentFlexibility: 'Payment flexibility',
  reliableDomesticCompany: 'Reliable domestic company',
  products: 'Products',
  menu: 'Menu',
  stores: 'Stores',
  campaigns: 'Campaigns',
  showPassword: 'Show password',
  hidePassword: 'Hide password',
  myAccount: 'My account',
  saveDetails: 'Save details',
  address: 'Address',
  deleteAddress: 'Delete address',
  editAddress: 'Edit address',
  addAddress: 'Add address',
  saveAddress: 'Save address',
  defaultShippingAddress: 'Default shipping address',
  defaultBillingAddress: 'Default billing address',
  defaultBillingAndShippingAddress: 'Default billing and shipping address',
  validator: {
    required: 'This field is required',
    numeric: 'Please use numbers only',
    postcode: {
      minLength: 'Please enter {n} numbers'
    },
    phone: {
      minLength: 'Phone number must be at least {n} characters long',
      format: 'Please match the requested format: +358401234567',
      countryCode: 'Please start with a country code (e.g. +358)'
    },
    email: {
      format: 'Please enter a valid email address'
    }
  }
}
